/* eslint-disable */
import _ from 'lodash';

import { Events } from '../services/Constants';
import DenodoService from '../services/DenodoService';
import DocumentService from '../services/DocumentService';
import EmployeeService from '../services/EmployeeService';
import EventService from '../services/EventService';
import HubSpotService from '../services/HubSpotService';
import ManagerService from '../services/ManagerService';
import OktaService from '../services/OktaService';
import {
  RequestActionOpts as BaseRequestActionOpts,
  RequestInitReducer,
  RequestReducer,
} from '../utils/Request';
import { updateLoading, withLoadingPromise } from './CommonActions';

export const types = {
  RESOURCES_PROFILE: 'RESOURCES_PROFILE',
  RESOURCES_PLANS: 'RESOURCES_PLANS',
  RESOURCES_ALL_PLANS: 'RESOURCES_ALL_PLANS',
  RESOURCES_PLAN_TEAM: 'RESOURCES_PLAN_TEAM',
  RESOURCES_PLAN_ADMIN_USERLIST: 'RESOURCES_PLAN_ADMIN_USERLIST',
  RESOURCES_TEAM_PICTURES: 'RESOURCES_TEAM_PICTURES',
  RESOURCES_NEPC_PUBLISHED: 'RESOURCES_NEPC_PUBLISHED',
  RESOURCES_CLIENT_DELIVERABLES: 'RESOURCES_CLIENT_DELIVERABLES',
  RESOURCES_RESEARCH_WRITEUPS: 'RESOURCES_RESEARCH_WRITEUPS',
  RESOURCES_PLAN_MANAGER_ACTIVITIES: 'RESOURCES_PLAN_MANAGER_ACTIVITIES',
  RESOURCES_FOCUSED_PLACEMENT_LISTS: 'RESOURCES_FOCUSED_PLACEMENT_LISTS',
  RESOURCES_FOCUSED_PLACEMENT_LIST: 'RESOURCES_FOCUSED_PLACEMENT_LIST',
  RESOURCES_FPL_NEPC_OPINION: 'RESOURCES_FPL_NEPC_OPINION',
  RESOURCES_FPL_RESEARCH_WRITEUPS: 'RESOURCES_FPL_RESEARCH_WRITEUPS',
  RESOURCES_FPL_MANAGER_ACTIVITIES: 'RESOURCES_FPL_MANAGER_ACTIVITIES',
  RESOURCES_FPL_EVEST_NARRATIVES: 'RESOURCES_FPL_EVEST_NARRATIVES',
  RESOURCES_BLOG: 'RESOURCES_BLOG',
  RESOURCES_MANAGER_SUMMARY: 'RESOURCES_MANAGER_SUMMARY',
  RESOURCES_MANAGER_DD_EVENTS: 'RESOURCES_MANAGER_DD_EVENTS',
  RESOURCES_MANAGER_ACTIVITIES: 'RESOURCES_MANAGER_ACTIVITIES',
  RESOURCES_MANAGER_STRATEGIES: 'RESOURCES_MANAGER_STRATEGIES',
  RESOURCES_MANAGER_EVEST_NARRATIVES: 'RESOURCES_MANAGER_EVEST_NARRATIVES',
  RESOURCES_MY_MANAGERS: 'RESOURCES_MY_MANAGERS',
  RESOURCES_ALL_MANAGERS: 'RESOURCES_ALL_MANAGERS',
  RESOURCES_SEARCH_MANAGERS: 'RESOURCES_SEARCH_MANAGERS',
  RESOURCES_PLAN_DD_EVENTS: 'RESOURCES_PLAN_DD_EVENTS',
  RESOURCES_DD_EVENT: 'RESOURCES_DD_EVENT',
  RESOURCES_EMPLOYEE_DD_EVENTS_MY_MANAGER_LIST:
    'RESOURCES_EMPLOYEE_DD_EVENTS_MY_MANAGER_LIST',
  RESOURCES_EMPLOYEE_DD_EVENTS_ALL_MANAGERS_LIST:
    'RESOURCES_EMPLOYEE_DD_EVENTS_ALL_MANAGERS_LIST',
  RESOURCES_EMPLOYEE_MEETING_NOTES_LIST:
    'RESOURCES_EMPLOYEE_MEETING_NOTES_LIST',
  RESOURCES_EMPLOYEE_MEETING_NOTES_ALL_MANAGERS_LIST:
    'RESOURCES_EMPLOYEE_MEETING_NOTES_ALL_MANAGERS_LIST',
  RESOURCES_EMPLOYEE_MEETING_NOTES_PLANS:
    'RESOURCES_EMPLOYEE_MEETING_NOTES_PLANS',
  RESOURCES_EMPLOYEE_INVESTMENT_FIRM: 'RESOURCES_EMPLOYEE_INVESTMENT_FIRM',
  RESOURCES_MARKET_UPDATE_DOCS: 'RESOURCES_MARKET_UPDATE_DOCS',
  RESOURCES_EMPLOYEE_DD_EVENTS_PLAN_LIST:
    'RESOURCES_EMPLOYEE_DD_EVENTS_PLAN_LIST',
  RESOURCES_DOCUMENT_META: 'RESOURCES_DOCUMENT_META',
  RESOURCES_NOTIFICATIONS: 'RESOURCES_NOTIFICATIONS',
  RESOURCES_SKYAN_ACCESS: 'RESOURCES_SKYAN_ACCESS',
};

const typesAsArray = _.keys(types);

export const DEFAULT_STATE = {
  ...RequestInitReducer(typesAsArray),
};

export const Reducer = (state = DEFAULT_STATE, action) => {
  return RequestReducer(state, typesAsArray, action);
};

const withLoadingService = (dispatch, service) => (...args) => {
  return new Promise(resolve => {
    const promise = service(...args);
    withLoadingPromise(dispatch, promise);
    return resolve(promise);
  });
};

const withoutLoadingService = (dispatch, service) => (...args) => {
  return new Promise(resolve => {
    const promise = service(...args);
    const done = () => dispatch(updateLoading(false));
    promise.then(done).catch(done);
    return resolve(promise);
  });
};

const RequestActionOpts = (type, service, opts, ...args) => dispatch =>
  dispatch(
    BaseRequestActionOpts(
      type,
      withLoadingService(dispatch, service),
      opts,
      ...args
    )
  );

const RequestActionOptsWithoutLoading = (
  type,
  service,
  opts,
  ...args
) => dispatch =>
  dispatch(
    BaseRequestActionOpts(
      type,
      withoutLoadingService(dispatch, service),
      opts,
      ...args
    )
  );
const RequestActionMap = (type, service, mapper, ...args) =>
  RequestActionOpts(type, service, { mapper }, ...args);

const RequestAction = (type, service, ...args) =>
  RequestActionMap(type, service, o => o, ...args);

export const loadProfileResources = (skipCache, mapper) =>
  RequestActionOpts(
    types.RESOURCES_PROFILE,
    DenodoService().getContactDetails,
    { mapper, skipCache }
  );

export const loadUserPlans = (email, loader, skipCache, mapper) =>
  RequestActionOpts(
    types.RESOURCES_PLANS,
    loader,
    { mapper, skipCache },
    email
  );

export const loadAllPlans = letter =>
  RequestAction(
    types.RESOURCES_ALL_PLANS,
    DenodoService().getEmployeeLetterPlans,
    letter
  );

export const loadPlanTeam = planId =>
  RequestAction(types.RESOURCES_PLAN_TEAM, DenodoService().getPlanTeam, planId);

export const loadTeamPicturesResources = (emails, loader, mapper) =>
  RequestActionMap(types.RESOURCES_TEAM_PICTURES, loader, mapper, emails);

export const loadPlanAdminUserListResource = (planId, force = false) =>
  RequestActionOpts(
    types.RESOURCES_PLAN_ADMIN_USERLIST,
    DenodoService().getPlanUserList,
    { skipCache: force },
    planId
  );

export const loadNepcPublished = (planType, force = false) =>
  RequestActionOpts(
    types.RESOURCES_NEPC_PUBLISHED,
    DocumentService().getResearchDocuments,
    {
      mapper: DocumentService().documentListFromMFiles,
      skipCache: force,
    },
    planType
  );

export const checkAndDispatchDocument = (planIds, planTypes, email, fileId) =>
  RequestActionOpts(
    types.RESOURCES_DOCUMENT_META,
    DocumentService().checkDocumentsByPlan,
    {
      mapper: DocumentService().documentFromMFiles,
      skipCache: false,
    },
    planIds,
    planTypes,
    email,
    fileId
  );

export const loadNepcPublishedWithoutLoading = (planType, force) =>
  RequestActionOptsWithoutLoading(
    types.RESOURCES_NEPC_PUBLISHED,
    DocumentService().getResearchDocuments,
    {
      mapper: DocumentService().documentListFromMFiles,
      skipCache: force,
    },
    planType
  );

export const loadClientDeliverables = (planId, force = false) =>
  RequestActionOptsWithoutLoading(
    types.RESOURCES_CLIENT_DELIVERABLES,
    DocumentService().getClientDeliverables,
    {
      mapper: DocumentService().documentListFromMFiles,
      skipCache: force,
    },
    planId
  );

export const loadResearchWriteUps = planId =>
  RequestAction(
    types.RESOURCES_RESEARCH_WRITEUPS,
    DenodoService().getPlanMeetingNotes,
    planId
  );

export const loadManagerActivities = planId =>
  RequestAction(
    types.RESOURCES_PLAN_MANAGER_ACTIVITIES,
    DenodoService().getManagerActivities,
    planId
  );

export const loadFocusedPlacementLists = () =>
  RequestActionMap(
    types.RESOURCES_FOCUSED_PLACEMENT_LISTS,
    DenodoService().getFocusedPlacementLists,
    data => _.keyBy(data, 'fplListId')
  );

export const loadFocusedPlacementListResource = (
  fplListId,
  mapper
) => dispatch => {
  dispatch(
    RequestActionMap(
      types.RESOURCES_FOCUSED_PLACEMENT_LIST,
      DenodoService().getStrategyList,
      mapper,
      fplListId
    )
  );
};

export const loadFPLNepcOpinionResource = (strategyId, mapper) =>
  RequestActionMap(
    types.RESOURCES_FPL_NEPC_OPINION,
    DenodoService().getFPLNepc,
    mapper,
    strategyId
  );

export const loadFPLResearchWriteups = strategyId =>
  RequestAction(
    types.RESOURCES_FPL_RESEARCH_WRITEUPS,
    DenodoService().getFPLResearch,
    strategyId
  );

export const loadFPLManagerActivities = strategyId =>
  RequestAction(
    types.RESOURCES_FPL_MANAGER_ACTIVITIES,
    DenodoService().getFPLManagerActivities,
    strategyId
  );

export const sendNotificationTest = notifications => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken, user },
    } = getState();
    return HubSpotService().sendNotificationTest(accessToken, notifications);
  };
};
export const sendNotificationEmployees = () => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken, user },
    } = getState();
    EventService().addEvent(Events.RUN_EMPLOYEES_NOTIFICATIONS, user.email);
    return HubSpotService().sendNotificationEmployees(accessToken);
  };
};
export const sendNotificationClients = () => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken, user },
    } = getState();
    EventService().addEvent(Events.RUN_CLIENTS_NOTIFICATIONS, user.email);
    return HubSpotService().sendNotificationClients(accessToken);
  };
};

export const loadNotificationFilterResource = () =>
  RequestAction(
    types.RESOURCES_NOTIFICATIONS,
    OktaService().loadNotificationsFilter
  );

export const loadFPLeVest = strategyId =>
  RequestAction(
    types.RESOURCES_FPL_EVEST_NARRATIVES,
    DenodoService().getFPLEvest,
    strategyId
  );

export const loadMyManagers = planId =>
  RequestAction(
    types.RESOURCES_MY_MANAGERS,
    ManagerService().getMyManagersList,
    planId
  );

export const loadAllManagers = () =>
  RequestAction(
    types.RESOURCES_ALL_MANAGERS,
    ManagerService().loadAllManagerStrategies
  );

export const loadSearchManagers = planId =>
  RequestAction(
    types.RESOURCES_SEARCH_MANAGERS,
    ManagerService().getManagerSearchListByPlanId,
    planId
  );

export const loadManagerSummaryResource = (managerId, mapper) =>
  RequestActionMap(
    types.RESOURCES_MANAGER_SUMMARY,
    ManagerService().loadManagerSummary,
    mapper,
    managerId
  );

export const loadManagerManagerActivities = managerId =>
  RequestAction(
    types.RESOURCES_MANAGER_ACTIVITIES,
    ManagerService().loadManagerActivitiesByFirm,
    managerId
  );

export const loadManagerStrategies = managerId =>
  RequestAction(
    types.RESOURCES_MANAGER_STRATEGIES,
    ManagerService().loadManagerStrategies,
    managerId
  );

export const loadManagerEVest = managerId =>
  RequestAction(
    types.RESOURCES_MANAGER_EVEST_NARRATIVES,
    ManagerService().loadManagerEVest,
    managerId
  );

export const loadPortfolioDDs = planId =>
  RequestAction(
    types.RESOURCES_PLAN_DD_EVENTS,
    DenodoService().getPlanDDEvents,
    planId
  );

export const loadDDEventResource = (uniqueId, onData) =>
  RequestActionOpts(
    types.RESOURCES_DD_EVENT,
    DenodoService().getDDEvent,
    { onData },
    uniqueId
  );

export const loadSkyanAuthorization = email =>
  RequestActionOpts(
    types.RESOURCES_SKYAN_ACCESS,
    DenodoService().getSkyanAuthorization,
    { skipCache: true },
    email
  );

export const loadEmployeeDDEventsMyManager = email =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_DD_EVENTS_MY_MANAGER_LIST,
    EmployeeService().getEmployeeDDEvents,
    email,
    0
  );

export const loadEmployeeDDEventsAllManagers = email =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_DD_EVENTS_ALL_MANAGERS_LIST,
    EmployeeService().getEmployeeDDEvents,
    email,
    1
  );

export const loadEmployeeMeetingNotes = email =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_MEETING_NOTES_LIST,
    EmployeeService().getEmployeeMeetingNotes,
    email,
    0
  );

export const loadEmployeeMeetingNotesAllManagers = email =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_MEETING_NOTES_ALL_MANAGERS_LIST,
    EmployeeService().getEmployeeMeetingNotes,
    email,
    1
  );

export const loadEmployeeMeetingNotesPlans = (email, writeUpId) =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_MEETING_NOTES_PLANS,
    EmployeeService().getEmployeeMeetingNotesPlans,
    email,
    writeUpId
  );

export const loadEmployeeDDeventsAffectedPlans = eventId =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_DD_EVENTS_PLAN_LIST,
    EmployeeService().getEmployeeDDEventsAffectedPlans,
    eventId
  );

export const loadEmployeeInvestmentFirm = firmId =>
  RequestAction(
    types.RESOURCES_EMPLOYEE_INVESTMENT_FIRM,
    EmployeeService().getEmployeeMeetingNotesPlans,
    firmId
  );

export const loadMarketUpdateDocs = (force = false) =>
  RequestActionOpts(
    types.RESOURCES_MARKET_UPDATE_DOCS,
    EventService().eventsByType,
    { skipCache: force },
    Events.OPEN_MARKET_DOCUMENT
  );

export const validateVersion = () => {
  return dispatch => {
    return HubSpotService()
      .getVersion()
      .then(response => {
        const latestVersion = response.data.version;
        const currentVersion = global.appVersion;
        if (latestVersion !== currentVersion) {
          console.log('The versions are different. The cache will be cleaned.');
          console.log(`Cached [${currentVersion}] latest [${latestVersion}]`);
          console.log('Clearing cache and hard reloading...');
          if (caches) {
            caches.keys().then(function(names) {
              for (let name of names) {
                console.log(`deleting ${name} from cache`);
                caches.delete(name);
              }
            });
          }
          window.location.reload(true);
        } else {
          console.log(
            'The cache is aligned with latest version ' + latestVersion
          );
        }
      });
  };
};
