import _ from 'lodash';

const API = '/api/v1';

const buildEnvironment = () => {
  const _makeEnvironment = (
    PROTOCOL,
    HOST,
    PORT,
    RECAPTCHA_SITEKEY,
    CRM_PLAN_URL
  ) => {
    const forPATH = PATH => ({
      PROTOCOL,
      HOST,
      PORT,
      PATH,
    });
    return {
      OKTA: forPATH(`/okta`),
      DENODO: forPATH(`/denodo${API}`),
      MFILES: forPATH(`/mfiles${API}`),
      EVENTS: forPATH(`/events${API}`),
      CRM: forPATH(`/crm${API}`),
      PRINT: forPATH(`/print${API}`),
      HUBSPOT: forPATH(`/hubspot${API}`),
      RECAPTCHA_SITEKEY,
      CRM_PLAN_URL,
      HOST,
      PORT,
    };
  };

  const ENVIRONMENTS = {
    LOCAL: {
      /** **************   Use QA environment?      ****************/
      ..._makeEnvironment(
        'https://',
        'clienthub-qa.nepc.com',
        null,
        '6LfdBV8UAAAAAKhSnMdwaE4JZ9HgcmOFUds0p5Ly',
        'https://nepc-test.backstopsolutions.com/backstop/fundaccounting/portfolio/ManageHedgeFund.action?display=&entityId={{planId}}'
      ),
      /** **************   Set LOCAL Okta (needed)  ****************/
      OKTA: {
        PROTOCOL: 'http://',
        HOST: 'localhost',
        PORT: 8201,
        PATH: '',
      },
      // HUBSPOT: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8207,
      //   PATH: API,
      // },
      /** **************   Use LOCAL environment?   ****************/
      // DENODO: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8202,
      //   PATH: API,
      // },
      // MFILES: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8203,
      //   PATH: API,
      // },
      // EVENTS: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8204,
      //   PATH: API,
      // },
      // CRM: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8205,
      //   PATH: API,
      // },
      // PRINT: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8207,
      //   PATH: '/api/v1',
      // },
    },
    QA: _makeEnvironment(
      'https://',
      'clienthub-qa.nepc.com',
      null,
      '6Lc1H6wZAAAAAHK1KiuL9NhX7qt4FgR_GLK12wz2',
      'https://nepc-test.backstopsolutions.com/backstop/fundaccounting/portfolio/ManageHedgeFund.action?display=&entityId={{planId}}'
    ),
    UAT: _makeEnvironment(
      'https://',
      'clienthub-uat.nepc.com',
      null,
      '6LeQIawZAAAAAGSCaiaaq9E1pR7NMpMHeo8G0w6X',
      'https://nepc.backstopsolutions.com/backstop/fundaccounting/portfolio/ManageHedgeFund.action?display=&entityId={{planId}}'
    ),
    PREPROD: _makeEnvironment(
      'https://',
      'clienthub-preprod.nepc.com',
      null,
      '6LcuKl8UAAAAAD6oIftKuMK6ln-9eYOL7YDVaW-q',
      'https://nepc.backstopsolutions.com/backstop/fundaccounting/portfolio/ManageHedgeFund.action?display=&entityId={{planId}}'
    ),
    PROD: _makeEnvironment(
      'https://',
      'clienthub.nepc.com',
      null,
      '6LcuKl8UAAAAAD6oIftKuMK6ln-9eYOL7YDVaW-q',
      'https://nepc.backstopsolutions.com/backstop/fundaccounting/portfolio/ManageHedgeFund.action?display=&entityId={{planId}}'
    ),
  };

  const currentDomain = window && window.location && window.location.hostname;
  const domainMapping = {
    localhost: 'LOCAL',
    'clienthub-qa.nepc.com': 'QA',
    'clienthub-uat.nepc.com': 'UAT',
    'clienthub-preprod.nepc.com': 'PREPROD',
  };
  const env = domainMapping[currentDomain] || 'PROD';
  const generateUrls = endpoints =>
    _.mapValues(endpoints, e => {
      if (e && e.HOST) {
        const PORT = e.PORT ? ':' + e.PORT : '';
        return {
          ...e,
          URL: `${e.PROTOCOL}${e.HOST}${PORT}${e.PATH}`,
          CRM_PLAN_URL: e.CRM_PLAN_URL,
        };
      } else {
        return e;
      }
    });
  const withURLs = generateUrls(ENVIRONMENTS[env]);
  return {
    ENVIRONMENT: env,
    ...withURLs,
  };
};
const environmentDependentConstants = buildEnvironment();
const buildId = (process.env.REACT_APP_BUILD_ID || 'DEV').trim();

export const Events = {
  LOGIN: 'Login',
  OPEN_DOCUMENT: 'Open document',
  DOWNLOAD_DOCUMENT: 'Download document',
  OPEN_MEETING_NOTE: 'Open meeting',
  ACTIVATE_USER: 'Activate user',
  DEACTIVATE_USER: 'Deactivate user',
  UPDATE_PROFILE: 'Change contact Info',
  REVIEW: 'Last Reviewed Date',
  TERMS_AND_CONDITION: 'Terms and Conditions',
  OPEN_PLAN: 'Open plan',
  OPEN_FOCUSED_PLACEMENT_LIST: 'Open focused placement list',
  OPEN_STRATEGY: 'Open strategy',
  OPEN_DD_EVENT: 'Open dd event',
  PRINT_DUE_DILLIGENCE_EVENTS: 'Print Due Diligence Events notes',
  PRINT_MEETING_NOTES: 'Print meeting notes',
  PRINT_MESSAGES: 'Print system messages',
  PRINT_NEPC_OPINION: 'Print strategy nepc opinion',
  PRINT_EVESTMENT_NARRATIVE: 'Print eVestment Narrative',
  PRINT_PORTFOLIO_HOLDINGS: 'Print Portfolio Holdings',
  PRINT_EMPLOYEE_PORTFOLIO_HOLDINGS: 'Print Employee Portfolio Holdings',
  PRINT_PORTFOLIO_DDS: 'Print Portfolio DDs',
  PRINT_EMPLOYEE_PORTFOLIO_DDS: 'Print Employee Portfolio DDs',
  PRINT_MANAGER_MEETING_NOTES: 'Print portfolio manager meeting notes',
  PRINT_EMPLOYEE_MANAGER_MEETING_NOTES:
    'Print employee portfolio manager meeting notes',
  OPEN_MANAGER: 'Open Manager Details',
  OPEN_ANNOUNCEMENT: 'Open announcement',
  OPEN_MARKET_DOCUMENT: 'Open Market Update Document',
  RUN_EMPLOYEES_NOTIFICATIONS: 'Employee summary notification',
  RUN_CLIENTS_NOTIFICATIONS: 'Client summary notification',
};

const LED = 'Led';
const OWNED = 'Owned';

export const Constants = {
  // Environment
  ...environmentDependentConstants,
  API,
  BUILD_ID: buildId,
  // Page Views
  PLANS_VIEW: 'Portfolios',
  PLAN_TEAM_VIEW: 'Team',
  DOCUMENTS_VIEW: 'Documents',
  PROFILE_VIEW: 'Profile',
  REVIEW_VIEW: 'Review',
  TERMS_AND_CONDITIONS_VIEW: 'Terms and Conditions',
  ADMIN_VIEW: 'Admin',
  HUB_SETTINGS_VIEW: 'Settings',
  HUB_SETTINGS_MESSAGES_VIEW: 'Announcements settings',
  HUB_SETTINGS_MAINTENANCE_VIEW: 'Maintenance settings',
  HUB_SETTINGS_TERMS_VIEW: 'Terms and Conditions settings',
  INDEX_RETURNS: 'Index Returns',
  BLOG: 'Blog',
  SKYAN: 'Skyan',
  VIDEOS: 'Videos',
  // Document Views
  MY_TEAM_TITLE: 'My Team',
  ADMIN_TITLE: 'Admin',
  PLAN_DOCUMENTS_VIEW: 'Documents',
  PLAN_RESEARCH_DOCUMENTS_VIEW: 'NEPC Insights',
  PLAN_MARKET_UPDATES_VIEW: 'Market Updates',
  DOCUMENT_MARKET_UPDATES: 'Market Updates',
  PLAN_MANAGER_MEETING_NOTES_VIEW: 'Manager Meeting Notes',
  PLAN_MANAGER_NOTES_VIEW: 'Portfolio Manager Activities',
  PLAN_FOCUSED_PLACEMENT_LISTS: 'Investment Strategies',
  INVESTMENT_STRATEGY_GROUPS: 'Investment Strategies',
  PLAN_FOCUSED_PLACEMENT_LIST: 'Focused Placement List',
  PLAN_PERFORMANCE_PORTAL: 'Performance Portal',
  // Manager Pages
  PLAN_PORTFOLIO: 'Portfolio',
  PLAN_PORTFOLIO_HOLDINGS: 'Holdings',
  PLAN_PORTFOLIO_DD: 'Due Diligence Events',
  PLAN_MANAGER_STRATEGIES: 'Strategies',
  EMPLOYEE_MANAGER_ACTIVITIES: 'Employee Manager Activities',
  PLAN_MANAGER_EVEST_NARRATIVES: 'FPL eVestment Narratives',
  // Focused Placement List sections
  FPL_ESG: 'ESG Opinion',
  FPL_DEI: 'DEI Opinion',
  FPL_NEPC_NARRATIVES: 'NEPC Opinion',
  FPL_RESEARCH: 'FPL Manager Meeting Notes',
  FPL_MANAGER_ACTIVITIES: 'FPL Manager Activities',
  FPL_DD_EVENTS: 'DD Events',
  FPL_EVEST_NARRATIVES: 'eVestment Narratives',
  // Document Retrieval Types
  CLIENT_DELIVERABLES: 'ClientDeliverables',
  RESEARCH_DOCUMENTS: 'ResearchDocuments',
  // Consulting Resources
  CONSULTING_RESOURCES_VIEW: 'Consulting Resources',
  CR_DD_EVENTS_VIEW: 'Due Diligence Events',
  CR_MEETING_NOTES_VIEW: 'Manager Meeting Notes',
  // What is?
  WHAT_IS_DUE_DILIGENCE_RATING: 'What is Due Diligence Rating?',
  WHAT_IS_DUE_DILIGENCE_STATUS: 'What is Due Diligence Status?',
  WHAT_IS_BETA_GROUPS: 'What are Asset Groups?',
  WHAT_IS_ESG_RATING: 'What is ESG Rating?',
  WHAT_IS_DEI_RATING: 'What is DEI Rating?',
  WHAT_IS_OWNED_LED: 'What is Owned and Led?',
  // Other constants
  REVIEW_AFTER_X_MONTHS: 6,
  PERFORMANCE_PORTAL_URI:
    'https://nepc.okta.com/home/nepcllc_investorforceprod_1/0oa1q3xb85xqHzoMP2p7/aln1q40m9pRTmGj1x2p7',
  LOGIN_EVENT_TTL: 6 * 60 * 60 * 1000,
  // Roles
  EMPLOYEE_ROLE: 'Domain Users',
  SYSTEM_ADMIN_ROLE: 'Domain Users',

  MFILES_DOWN_MESSAGE:
    'Unable to display documents at this time. We are working on it.',
  // Internal Categories
  INTERNALLY_MANAGED: 'Internally Managed',

  // Rows in download PDF
  ROWS_PER_PAGE_DD_EVENTS: { firstPage: 18, postPages: 28 },
  ROWS_PER_PAGE_MEETING_NOTES: { firstPage: 36, postPages: 36 },
  ROWS_PER_PAGE_MY_MANAGERS: { firstPage: 12, postPages: 26 },

  // Labels and values for document filtering
  LED: LED,
  OWNED: OWNED,
  FILTER_VALUES: {
    esgRating: ['All', '1', '2', '3', '4', '5'],
    dueDiligenceRating: ['All', '1', '2', '3'],
    dueDiligenceRating_2: ['All', '1', '2'],
    diverseOwnedLed: ['All', OWNED, LED],
    deiRating: ['All', '1', '2', '3', '4', '5'],
  },
  FILTER_LABELS: {
    assetClass: {
      label: 'Asset Class',
      multiSelect: false,
      inputType: 'dropdown',
      showCounter: false,
    },
    esgRating: {
      label: 'ESG Rating',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    reasonForMeeting: {
      label: 'Reason For Meeting',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    dueDiligenceRating: {
      label: 'Due Diligence Rating',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    dueDiligenceRating_2: {
      label: 'Due Diligence Rating',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    diverseOwnedLed: {
      label: 'Diverse',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    betaGroup: {
      label: 'Asset Group',
      multiSelect: false,
      inputType: 'dropdown',
      showCounter: false,
    },
    deliverableType: {
      label: 'Document Type',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    type: {
      label: 'Type',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    category: {
      label: 'Category',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    newStatus: {
      label: 'New Status',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
    fplListName: {
      label: 'Strategy Group',
      multiSelect: false,
      inputType: 'dropdown',
      showCounter: false,
    },
    deiRating: {
      label: 'DEI Rating',
      multiSelect: true,
      inputType: 'buttons',
      showCounter: false,
    },
  },

  // Other
  QUILL_TOOLBAR_MODULE: {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, 'direction'],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      ['blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  },

  ASSET_CLASS_COLORS: {
    Balanced: { color: '#CA9518', order: 140 },
    'Cash / Money Market': { color: '#43505E', order: 190 },
    Commodities: { color: '#F5D68F', order: 150 },
    'Credit Opportunities': { color: '#1E99D8', order: 90 },
    'Domestic Equity': { color: '#16709E', order: 10 },
    'Domestic Fixed Income': { color: '#8CC94A', order: 60 },
    'Emerging Market Debt': { color: '#6ED0F7', order: 80 },
    'Emerging Market Equity': { color: '#2F89B7', order: 30 },
    GAA: { color: '#CA9518', order: 100 },
    'Global Equity': { color: '#3993C1', order: 40 },
    'Global Fixed Income': { color: '#D6F28C', order: 70 },
    'Hedge Fund': { color: '#C48F12', order: 110 },
    Infrastructure: { color: '#D7B871', order: 180 },
    'International Equity': { color: '#257FAD', order: 20 },
    'Lifecycle/Target Date': { color: '#CA9518', order: 130 },
    'Liquid Alternatives': { color: '#CA9518', order: 120 },
    Overlay: { color: '#485563', order: 200 },
    'Private Equity': { color: '#002060', order: 50 },
    'Private Debt': { color: '#37B2F1', order: 83 },
    'Real Assets': { color: '#DCBD76', order: 170 },
    'Real Estate': { color: '#F2CC73', order: 160 },
  },
};

export default Constants;
