/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getOktaSettings,
  validateVersion,
  redirect,
  logout,
} from '../../actions/index';
import Constants from '../../services/Constants';
import Login from '../login/Login';
import ChangePassword from '../change_password';
import Register from '../login/Register';
import ClientHUBTitle from '../ClientHUBTitle';
import Main from '../Main';
import Authenticated from '../Authenticated';
import NotFound from '../NotFound';
import PlansRouter from './PlansRouter';
import SkyanRouter from './SkyanRouter';
import MessagesPopup from '../messages/MessagesPopup';
import ConsultingResourcesRouter from './ConsultingResourcesRouter';
import Downloads from '../Downloads';
import VideosRouter from './VideosRouter';

export class AppRouter extends Component {
  componentDidMount() {
    this.props.getOktaSettings();

    this.props.validateVersion();
  }

  onAuthRequired({ history }) {
    const redirect = encodeURIComponent(history.location.pathname);
    history.push('/?to=' + redirect);
  }

  withMain = (selectedView, viewParams = {}) => {
    if (
      selectedView !== Constants.TERMS_AND_CONDITIONS_VIEW &&
      this.props.acceptedTerms === false
    ) {
      return this.withMain(Constants.TERMS_AND_CONDITIONS_VIEW);
    }
    return (
      <Fragment>
        <Main selectedView={selectedView} viewParams={viewParams} />
        <ClientHUBTitle selectedView={selectedView} viewParams={viewParams} />
      </Fragment>
    );
  };

  onProfile = () => {
    return this.withMain(Constants.PROFILE_VIEW);
  };

  onTerms = () => {
    return this.withMain(Constants.TERMS_AND_CONDITIONS_VIEW);
  };

  onPlans = () => {
    return this.withMain(Constants.PLANS_VIEW);
  };

  onConsultingResources = ({ match }) => {
    return this.props.okta.isEmployee ? (
      <ConsultingResourcesRouter
        match={match}
        withMain={this.withMain}
        isEmployee={this.props.employee}
      />
    ) : (
      this.onPlans()
    );
  };

  onSkyan = ({ match }) => {
    return <SkyanRouter match={match} withMain={this.withMain} />;
  };

  onVideos = ({ match }) => {
    return (
      <VideosRouter
        match={match}
        topics="research"
        categories="video"
        withMain={this.withMain}
      />
    );
  };

  onHUBAdmin = () => {
    return this.withMain(Constants.HUB_SETTINGS_VIEW);
  };

  onBlog = ({ match }) => {
    return (
      <VideosRouter
        match={match}
        topics=""
        categories="blog"
        withMain={this.withMain}
      />
    );
  };

  onHUBAdminMaintenance = () => {
    if (!this.props.okta.accessToken) {
      return this.onLoginMaintenance();
    }
    return this.withMain(Constants.HUB_SETTINGS_VIEW);
  };

  onPlan = ({ match }) => {
    return <PlansRouter match={match} withMain={this.withMain} />;
  };

  onDownloadDocuments = ({ match }) => {
    return (
      <div>
        <Downloads urlParams={match.params} />
        {this.onPlans()}
      </div>
    );
  };

  onLogin = () => {
    const { baseUrl, maintenanceMode } = this.props.oktaSettings;
    return (
      <Login
        baseUrl={baseUrl}
        maintenanceMode={maintenanceMode}
        isMaintenanceLogin={false}
      />
    );
  };

  onLoginMaintenance = () => {
    const { baseUrl, maintenanceMode } = this.props.oktaSettings;
    return (
      <Login
        baseUrl={baseUrl}
        maintenanceMode={maintenanceMode}
        isMaintenanceLogin={true}
      />
    );
  };

  onRegister = () => {
    return <Register />;
  };

  onChangePassword = () => {
    return <ChangePassword />;
  };

  onLoginCallback = () => {
    return <ImplicitCallback />;
  };

  onLogout = () => {
    this.props.logout();
    return null;
  };

  onNotFound = () => {
    return <NotFound />;
  };

  redirect = () => {
    if (this.props.redirectTo) {
      this.props.redirect(null);
      return <Redirect push={true} to={this.props.redirectTo} />;
    }
    return null;
  };

  routes = () => {
    const {
      clientId,
      issuer,
      redirectUri,
      maintenanceMode,
    } = this.props.oktaSettings;

    if (!issuer || !clientId) {
      return null;
    }
    if (maintenanceMode) {
      return (
        <Security
          issuer={issuer}
          client_id={clientId}
          redirect_uri={redirectUri}
          onAuthRequired={this.onAuthRequired}>
          <ClientHUBTitle {...this.props} viewParams={{}} />
          <Switch>
            <Route path="/settings" render={this.onHUBAdminMaintenance} />
            <Route path="/implicit/callback" render={this.onLoginCallback} />
            <Route path="/logout" render={this.onLogout} />
            <Route path="/" render={this.onLogin} />
          </Switch>
          <Authenticated />
          <MessagesPopup />
        </Security>
      );
    }

    return (
      <Security
        issuer={issuer}
        client_id={clientId}
        redirect_uri={redirectUri}
        onAuthRequired={this.onAuthRequired}>
        <ClientHUBTitle {...this.props} viewParams={{}} />
        <Switch>
          <Route path="/register" exact={true} render={this.onRegister} />
          <SecureRoute
            path="/changePassword"
            exact={true}
            render={this.onChangePassword}
          />
          <SecureRoute
            path="/portfolios/Download/:fileId/:documentTittle"
            render={props => this.onDownloadDocuments(props)}
          />
          <SecureRoute path="/portfolios" render={this.onPlans} />
          <SecureRoute
            path="/consultingResources"
            render={this.onConsultingResources}
          />
          <SecureRoute path="/skyan" render={this.onSkyan} />
          <SecureRoute path="/videos" render={this.onVideos} />
          <SecureRoute path="/blog" render={this.onBlog} />
          <SecureRoute path="/settings" render={this.onHUBAdmin} />
          <SecureRoute
            path="/portfolio/:clientLetter/:slugPlanName"
            render={this.onPlan}
          />
          <SecureRoute path="/profile" render={this.onProfile} />
          <Route path="/terms" render={this.onTerms} />
          <Route path="/logout" render={this.onLogout} />
          <Route path="/implicit/callback" render={this.onLoginCallback} />
          <Route path="/" exact={true} render={this.onLogin} />
          <Route render={this.onNotFound} />
        </Switch>
        <Authenticated />
        <MessagesPopup />
      </Security>
    );
  };

  render() {
    return <Router>{this.redirect() || this.routes()}</Router>;
  }
}

AppRouter.propTypes = {
  oktaSettings: PropTypes.object.isRequired,
  getOktaSettings: PropTypes.func.isRequired,
  validateVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  logout: PropTypes.func.isRequired,
  okta: PropTypes.object.isRequired,
  acceptedTerms: PropTypes.bool,
  employee: PropTypes.bool,
  email: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    okta: state.okta,
    oktaSettings: state.okta.settings,
    redirectTo: state.ui.redirect,
    acceptedTerms: state.terms.accepted,
  };
};

export default connect(mapStateToProps, {
  getOktaSettings,
  validateVersion,
  redirect,
  logout,
})(AppRouter);
