import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, Alert } from 'reactstrap';

export const RunNotificationClientModal = _props => {
  return (
    <Modal
      isOpen={_props.state.showDangerModalClients}
      toggle={_props.toggleDangerModalClients}>
      <ModalBody>
        <Alert color="danger">
          <h4 className="alert-heading">Warning</h4>
          Warning! This process will send the notification for all the enabled
          audience. INCLUDING CLIENTS. Are you sure do you want to start this
          process out of scheduled time?
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={_props.props.sendNotificationClients}>
          Run Notifications
        </Button>
        <Button color="secondary" onClick={_props.toggleDangerModalClients}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RunNotificationClientModal.propTypes = {
  state: PropTypes.object,
  toggleDangerModalClients: PropTypes.func,
  sendNotificationClients: PropTypes.func,
};
