import { dateColumn } from '../../../services/DateService';
import { currencyColumn } from '../../../services/NumberService';
import { textColumn } from '../../../services/StringService';
import DownloadDocumentButton from './DownloadDocumentButton';
import OpenDDEventLink from './OpenDDEventLink';
import OpenManagerNotesLink from './OpenManagerNotesLink';
import OpenManagerStrategy from './OpenManagerStrategy';
import OpenManagerSummary from './OpenManagerSummary';
import OpenMeetingNotesLink from './OpenMeetingNotesLink';

const downloadIconColumnDef = {
  maxWidth: 35,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  sortable: false,
  resizable: false,
};

export const researchFileModeColumnDefs = [
  {
    cellRenderer: DownloadDocumentButton,
    ...downloadIconColumnDef,
  },
  {
    field: 'title',
    headerName: 'File Name',
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'deliverableType',
    headerName: 'Document Type',
    ...textColumn,
  },
  {
    field: 'publishedDate',
    headerName: 'Published Date',
    sort: 'desc',
    ...dateColumn,
    width: 180,
  },
];

export const clientFileModeColumnDefs = [
  {
    cellRenderer: DownloadDocumentButton,
    ...downloadIconColumnDef,
  },
  {
    field: 'title',
    headerName: 'File Name',
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'deliverableType',
    headerName: 'Document Type',
    ...textColumn,
  },
  {
    field: 'asOfDate',
    headerName: 'As Of Date',
    minWidth: 160,
    ...dateColumn,
  },
  {
    field: 'publishedDate',
    headerName: 'Published Date',
    ...dateColumn,
    width: 180,
  },
];

export const meetingNotesColumnDefs = [
  {
    field: 'firm',
    headerName: 'Investment Firm',
    cellRenderer: OpenManagerSummary,
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'subject',
    headerName: 'Meeting Subject',
    cellRenderer: OpenMeetingNotesLink,
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'meetingDate',
    headerName: 'Meeting Date',
    width: 100,
    sort: 'desc',
    ...dateColumn,
  },
];

export const managerNotesColumnDefs = [
  {
    field: 'subject',
    headerName: 'Subject',
    cellRenderer: OpenManagerNotesLink,
    ...textColumn,
    minWidth: 120,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 80,
    maxWidth: 250,
    ...textColumn,
  },
  {
    field: 'firm',
    headerName: 'Investment Firm',
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 100,
    sort: 'desc',
    ...dateColumn,
  },
  {
    field: 'newStatus',
    headerName: 'New Status',
    minWidth: 100,
    ...textColumn,
  },
];

export const focusedPlacementListsColumnDefs = [
  {
    field: 'investmentStrategyName',
    headerName: 'Strategy Name',
    cellRenderer: OpenManagerStrategy,
    ...textColumn,
    minWidth: 280,
    maxWidth: 540,
    flex: 1,
    suppressSizeToFit: true,
  },
  {
    field: 'investmentFirmName',
    headerName: 'Investment Firm',
    flex: 1,
    ...textColumn,
    minWidth: 250,
    maxWidth: 260,
  },
  {
    field: 'dueDiligenceRating',
    headerName: 'Due Diligence Rating',
    minWidth: 120,
    maxWidth: 145,
    suppressSizeToFit: true,
    sort: 'asc',
  },
  {
    field: 'esgRating',
    headerName: 'ESG Rating',
    minWidth: 115,
    maxWidth: 125,
    suppressSizeToFit: true,
  },
  {
    field: 'deiRating',
    headerName: 'DEI Rating',
    minWidth: 115,
    maxWidth: 125,
    flex: 2,
    suppressSizeToFit: true,
  },
  {
    field: 'diverseOwnedLed',
    headerName: 'Diverse Owned Led',
    maxWidth: 135,
    width: 120,
    suppressSizeToFit: true,
  },
  {
    field: 'fplListName',
    headerName: 'Strategy Group',
    flex: 1,
    ...textColumn,
    minWidth: 250,
    maxWidth: 260,
  },
  {
    field: 'assetClass',
    headerName: 'Asset Class',
    ...textColumn,
    minWidth: 200,
    maxWidth: 550,
  },
  {
    field: 'betaGroup',
    headerName: 'Asset Group',
    ...textColumn,
    minWidth: 135,
  },
];

export const focusedPlacementListColumnDefs = [
  {
    field: 'investmentFirmName',
    headerName: 'Investment Firm',
    sort: 'asc',
    cellRenderer: OpenManagerSummary,
    flex: 1,
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'investmentStrategyName',
    headerName: 'Investment Strategy',
    cellRenderer: OpenManagerStrategy,
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'dueDiligenceRating',
    headerName: 'Due Diligence Rating',
    width: 175,
    suppressSizeToFit: true,
  },
  {
    field: 'strategyDueDiligenceStatus',
    headerName: 'Due Diligence Status',
    ...textColumn,
    width: 175,
    suppressSizeToFit: true,
  },
  {
    field: 'esgRating',
    headerName: 'ESG Rating',
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    field: 'diverseOwnedLed',
    headerName: 'Diverse Owned/Led',
    minWidth: 100,
    suppressSizeToFit: true,
  },
];

export const fplResearchModeColumnDefs = [
  {
    field: 'subject',
    headerName: 'Subject',
    cellRenderer: OpenMeetingNotesLink,
    ...textColumn,
    minWidth: 100,
  },
  {
    field: 'meetingDate',
    headerName: 'Date',
    sort: 'desc',
    minWidth: 100,
    ...dateColumn,
  },
];

const baseDDColumnDefs = [
  {
    field: 'ddSubcategory',
    headerName: 'Subcategory',
    ...textColumn,
    width: 109,
    minWidth: 109,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: 'eventDate',
    headerName: 'Date',
    sort: 'desc',
    width: 120,
    ...dateColumn,
  },
  {
    field: 'firmDueDiligenceStatus',
    headerName: 'Status Change',
    width: 150,
    ...textColumn,
    suppressSizeToFit: true,
  },
  {
    field: 'levelOfImpact',
    headerName: 'Level of Impact',
    ...textColumn,
    width: 150,
  },
];

const lockedColumn = {
  suppressMovable: true,
  sortable: false,
  suppressHeaderMenuButton: true,
  resizable: false,
};

const baseMyManagersColumnDefs = [
  {
    field: 'strategyName',
    headerName: 'Strategy',
    cellRenderer: OpenManagerStrategy,
    ...textColumn,
    sort: `asc`,
    minWidth: 150,
    width: 250,
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    resizable: false,
  },
  {
    field: 'firmName',
    headerName: 'Manager',
    ...textColumn,
    flex: 5,
  },
  {
    field: 'liquidityName',
    headerName: 'Liquidity',
    width: 150,
    minWidth: 150,
    maxWidth: 400,
    suppressSizeToFit: true,
    ...lockedColumn,
  },
  {
    field: 'assetValue',
    headerName: 'Asset Value',
    type: 'rightAligned',
    headerClass: 'ag-header-cell-text-right',
    ...currencyColumn,
    width: 160,
    minWidth: 160,
    maxWidth: 400,
    suppressSizeToFit: true,
    aggFunc: 'sum',
    ...lockedColumn,
  },
  {
    field: 'assetDate',
    headerName: 'As Of Date',
    width: 160,
    minWidth: 160,
    maxWidth: 400,
    ...dateColumn,
    ...lockedColumn,
  },
  {
    field: 'dueDiligenceRating',
    headerName: 'Due Diligence Rating',
    width: 155,
    suppressSizeToFit: true,
  },
  {
    field: 'esgRating',
    headerName: 'ESG Rating',
    minWidth: 130,
    flex: 1,
    suppressSizeToFit: true,
  },
  {
    field: 'diverseOwnedLed',
    headerName: 'Diverse Owned/Led',
    width: 185,
    maxWidth: 195,
    suppressSizeToFit: true,
  },
];

export const myManagersAssetColumnDefs = [
  {
    field: 'assetType',
    headerName: 'Asset Class',
    rowGroup: true,
    maxWidth: 220,
    hide: true,
    ...textColumn,
  },
  ...baseMyManagersColumnDefs,
];

// export const myManagersClientAssetColumnDefs = [
//   {
//     field: 'clientAssetClass',
//     headerName: 'Asset Class',
//     ...textColumn,
//     rowGroup: true,
//     maxWidth: 220,
//     hide: true,
//   },
//   ...baseMyManagersColumnDefs,
// ];

export const myManagersDDColumnDefs = [
  {
    field: 'investmentFirmName',
    headerName: 'Investment Firm',
    cellRenderer: OpenManagerSummary,
    ...textColumn,
    minWidth: 180,
  },
  {
    field: 'eventTitle',
    headerName: 'Event Title',
    width: 250,
    cellRenderer: OpenDDEventLink,
    ...textColumn,
    suppressSizeToFit: true,
  },
  ...baseDDColumnDefs,
];

export const managerDDEventsColumnDefs = [
  {
    field: 'eventTitle',
    headerName: 'Event',
    cellRenderer: OpenDDEventLink,
    ...textColumn,
    minWidth: 100,
  },
  ...baseDDColumnDefs,
];

export const managerStrategiesColumnDefs = [
  {
    field: 'investmentStrategyName',
    headerName: 'Strategy Name',
    sort: 'asc',
    cellRenderer: OpenManagerStrategy,
    ...textColumn,
    minWidth: 280,
    flex: 1,
    suppressSizeToFit: true,
  },
  {
    field: 'assetClass',
    headerName: 'Asset Class',
    ...textColumn,
    minWidth: 200,
    maxWidth: 250,
  },
  {
    field: 'betaGroup',
    headerName: 'Asset Group',
    ...textColumn,
    minWidth: 135,
    maxWidth: 150,
  },
  {
    field: 'dueDiligenceRating',
    headerName: 'Due Diligence Rating',
    minWidth: 165,
    maxWidth: 220,
  },
  {
    field: 'strategyDueDiligenceStatus',
    headerName: 'Due Diligence Status',
    ...textColumn,
    minWidth: 140,
    maxWidth: 190,
  },
  {
    field: 'esgRating',
    headerName: 'ESG Rating',
    minWidth: 110,
    maxWidth: 140,
  },
  {
    field: 'diverseOwnedLed',
    headerName: 'Diverse Owned/Led',
    minWidth: 90,
    flex: 2,
    suppressSizeToFit: true,
  },
];

export const managerManagerActivitiesColumnDefs = [
  {
    field: 'subject',
    headerName: 'Subject',
    cellRenderer: OpenManagerNotesLink,
    ...textColumn,
    minWidth: 120,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 80,
    maxWidth: 250,
    ...textColumn,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 100,
    sort: 'desc',
    ...dateColumn,
  },
  {
    field: 'newStatus',
    headerName: 'New Status',
    minWidth: 100,
    ...textColumn,
  },
];
